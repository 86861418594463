import React, { useState, useEffect } from 'react';
import { TextField, Button, Grid } from '@mui/material';

const RegisterForm = ({ onSuccess, email }) => {
  const [registerData, setRegisterData] = useState({
    email: email || '',
    confirmEmail: email || '',
    password: '',
    confirmPassword: '',
  });

  useEffect(() => {
    if (email) {
      setRegisterData((prevData) => ({
        ...prevData,
        email,
        confirmEmail: email,
      }));
    }
  }, [email]);

  const handleRegisterChange = (e) => {
    const { name, value } = e.target;
    setRegisterData({
      ...registerData,
      [name]: value,
    });
  };

  const handleRegisterSubmit = async (e) => {
    e.preventDefault();
    
    // Check if passwords match
    if (registerData.password !== registerData.confirmPassword) {
      alert('Passwords do not match');
      return;
    }
    
    // Check if email addresses match
    if (registerData.email !== registerData.confirmEmail) {
      alert('Email addresses do not match');
      return;
    }
  
    try {
      const response = await fetch('https://clipurr.ddnsfree.com:443/flyclaim/api/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: registerData.email, password: registerData.password }),
      });
  
      const data = await response.json();
  
      if (data.success) {
        localStorage.setItem('token', data.token);
        onSuccess(registerData.email);
      } else {
        // Display the specific error message from the server
        alert(`Registration failed: ${data.message}`);
      }
    } catch (error) {
      // Handle any network or unexpected errors
      alert(`Registration failed: ${error.message}`);
    }
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleRegisterSubmit(e);
    }
  };

  return (
    <form onSubmit={handleRegisterSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Email Address"
            variant="outlined"
            fullWidth
            name="email"
            value={registerData.email}
            onChange={handleRegisterChange}
            onKeyDown={handleKeyDown}
            required
            inputProps={{ onPaste: (e) => e.preventDefault(), onCopy: (e) => e.preventDefault() }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Confirm Email Address"
            variant="outlined"
            fullWidth
            name="confirmEmail"
            value={registerData.confirmEmail}
            onChange={handleRegisterChange}
            onKeyDown={handleKeyDown}
            required
            inputProps={{ onPaste: (e) => e.preventDefault(), onCopy: (e) => e.preventDefault() }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Password"
            variant="outlined"
            fullWidth
            name="password"
            type="password"
            value={registerData.password}
            onChange={handleRegisterChange}
            onKeyDown={handleKeyDown}
            required
            inputProps={{ onPaste: (e) => e.preventDefault(), onCopy: (e) => e.preventDefault() }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Confirm Password"
            variant="outlined"
            fullWidth
            name="confirmPassword"
            type="password"
            value={registerData.confirmPassword}
            onChange={handleRegisterChange}
            onKeyDown={handleKeyDown}
            required
            inputProps={{ onPaste: (e) => e.preventDefault(), onCopy: (e) => e.preventDefault() }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Register
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default RegisterForm;