import React, { useState } from 'react';
import { Typography, TextField, Button, Grid, Paper } from '@mui/material';
import { styled } from '@mui/system';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';



const FormContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginTop: theme.spacing(3),
  borderRadius: theme.spacing(3),
  backgroundColor: theme.palette.background.paper,
  width: '80vw', // Adjust the width to 50% of the viewport width
  textAlign: 'center'
}));

const StyledCardElement = styled(CardElement)({
  base: {
    fontSize: '16px',
    color: '#424770',
    '::placeholder': {
      color: '#aab7c4',
    },
  },
  invalid: {
    color: '#9e2146',
  },
  style: {
    base: {
      backgroundColor: '#fff', // Ensure white background
      padding: '16px',
      borderRadius: '4px',
      border: '1px solid #ced4da',
    },
  },
});

const PaymentForm = ({ email, setAlert, formData, onPaymentSuccess }) => {
  const stripe = useStripe();
  const elements = useElements();

  const handlePaymentSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });
    if (error) {
      setAlert({open: true, severity: 'error', message: error.message});
    } else {
      onPaymentSuccess(paymentMethod.id);
    }
  };

  return (
    <FormContainer elevation={3}>
      <form onSubmit={handlePaymentSubmit}>
        <Typography variant="h6" gutterBottom align="center">
         Enter Card Details
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <StyledCardElement />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Save Card Details
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormContainer>
  );
};


export default PaymentForm;