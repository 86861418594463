import { createTheme } from '@mui/material/styles';
const theme = createTheme({
  palette: {
    primary: {
      main: '#97d6ff', // A vibrant blue
    },
    secondary: {
      main: '#2af598', // A fresh lime green
    },
    background: {
      default: '#e0f7fa', // A light, airy teal for a subtle background
    },
  },
  typography: {
    fontFamily: [
      '"Merriweather", serif',
      '"Arial", sans-serif',
    ].join(','),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 20, // Maintain rounded corners for buttons
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: 15, // Keep text fields rounded
        },
      },
    },
  },
});

export default theme;