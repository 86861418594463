import React, { useState } from 'react';
import { Button, TextField, Typography, Paper, Alert } from '@mui/material';
import { styled } from '@mui/system';



const FormContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginTop: theme.spacing(3),
  borderRadius: theme.spacing(3),
  backgroundColor: theme.palette.background.paper,
  width: '80vw',
  textAlign: 'center'
}));

function SupportForm({ email }) {
  const [message, setMessage] = useState('');
  const [alert, setAlert] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (message.length > 1000) {
      setAlert('Message is too long. Please keep it under 1000 characters.');
      return;
    }

    try {
      const response = await fetch('https://clipurr.ddnsfree.com:443/flyclaim/api/support', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': localStorage.getItem('token'),
        },
        body: JSON.stringify({ email, message }),
      });

      if (response.ok) {
        setAlert('Support message sent successfully.');
        setMessage('');
      } else {
        setAlert('Failed to send support message. Please try again.');
      }
    } catch (error) {
      setAlert('An error occurred. Please try again.');
    }
  };

  return (
    <FormContainer elevation={3}>
      <Typography variant="h6" gutterBottom align="center">
        Support Form
      </Typography>
      {alert && <Alert severity="success">{alert}</Alert>}
      <Typography variant="body1" style={{ marginTop: '1rem' }}>
        Email: {email}
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Your message"
          multiline
          rows={4}
          variant="outlined"
          fullWidth
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          margin="normal"
        />
        <Button type="submit" variant="contained" color="primary" style={{ marginTop: '1rem', width: '100%' }}>
          Send
        </Button>
      </form>
    </FormContainer>
  );
}

export default SupportForm;