import React, { useState } from 'react';
import { TextField, Button, Grid } from '@mui/material';

const LoginForm = ({ onSuccess, email }) => {
  const [loginData, setLoginData] = useState({ email: email || '', password: '' });

  const handleLoginChange = (e) => {
    const { name, value } = e.target;
    setLoginData({
      ...loginData,
      [name]: value,
    });
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch('https://clipurr.ddnsfree.com:443/flyclaim/api/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(loginData),
    });

    const data = await response.json();
    if (data.auth) {
      localStorage.setItem('token', data.token);
      onSuccess(loginData.email);
    } else {
      alert('Login failed. Please check your credentials.');
    }
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleLoginSubmit(e);
    }
  };

  return (
    <form onSubmit={handleLoginSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Email Address"
            variant="outlined"
            fullWidth
            name="email"
            value={loginData.email}
            onChange={handleLoginChange}
            onKeyDown={handleKeyDown}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Password"
            variant="outlined"
            fullWidth
            name="password"
            type="password"
            value={loginData.password}
            onChange={handleLoginChange}
            onKeyDown={handleKeyDown}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Login
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default LoginForm;