import React, { useState, useEffect } from 'react';
import { Typography, TextField, Button, Grid, Paper, Autocomplete, Checkbox, FormControlLabel, Link } from '@mui/material';
import { styled } from '@mui/system';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';



const FormContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginTop: theme.spacing(3),
  borderRadius: theme.spacing(3),
  backgroundColor: theme.palette.background.paper,
  maxWidth: '80vw', // Default max width
  textAlign: 'center',
  [theme.breakpoints.down('md')]: {
    maxWidth: '80vw', // Max width when the viewport width is less than the medium breakpoint
  },
  [theme.breakpoints.up('md')]: {
    maxWidth: '40vw', // Max width when the viewport width is greater than the medium breakpoint
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

const ClaimForm = ({ setAlert, isLoggedIn, email, setUserEmail, onSubmit }) => {
  const [formData, setFormData] = useState({
    ticketNumber: '',
    bookingReference: '',
    flightNumber: '',
    departureDate: '',
    firstName: '',
    lastName: '',
    email: email || '',
    confirmEmail: email || '',
    departureAirport: null,
    arrivalAirport: null,
  });

  const [airportOptions, setAirportOptions] = useState([]);
  const [loadingAirports, setLoadingAirports] = useState(false);
  const [flightOptions, setFlightOptions] = useState([]);
  const [loadingFlights, setLoadingFlights] = useState(false);


  const [acceptedTerms, setAcceptedTerms] = useState(false); // State for the checkbox

  const supportedAirlines = ['AC', 'TS', 'F8', 'WG', 'WS'];

  const airports = [
    { name: 'Toronto Pearson International Airport', code: 'CYYZ' },
    { name: 'Vancouver International Airport', code: 'CYVR' },
    { name: 'Calgary International Airport', code: 'CYYC' },
    { name: 'Montreal-Pierre Elliott Trudeau International Airport', code: 'CYUL' },
    { name: 'Edmonton International Airport', code: 'CYEG' },
    { name: 'Ottawa Macdonald-Cartier International Airport', code: 'CYOW' },
    { name: 'Winnipeg James Armstrong Richardson International Airport', code: 'CYWG' },
    { name: 'Halifax Stanfield International Airport', code: 'CYHZ' },
    { name: 'Quebec City Jean Lesage International Airport', code: 'CYQB' },
    { name: 'Victoria International Airport', code: 'CYYJ' },
    { name: 'St. John\'s International Airport', code: 'CYYT' },
    { name: 'Kelowna International Airport', code: 'CYLW' },
    { name: 'Saskatoon John G. Diefenbaker International Airport', code: 'CYXE' },
    { name: 'Regina International Airport', code: 'CYQR' },
    { name: 'London International Airport', code: 'CYXU' },
    { name: 'Charlottetown Airport', code: 'CYYG' },
    { name: 'Greater Moncton Roméo LeBlanc International Airport', code: 'CYQM' },
    { name: 'Hamilton John C. Munro International Airport', code: 'CYHM' },
    { name: 'Abbotsford International Airport', code: 'CYXX' },
    { name: 'Fredericton International Airport', code: 'CYFC' },
    { name: 'Gander International Airport', code: 'CYQX' },
    { name: 'Deer Lake Regional Airport', code: 'CYDF' },
    { name: 'Saint John Airport', code: 'CYSJ' },
    { name: 'Sudbury Airport', code: 'CYSB' },
    { name: 'Kamloops Airport', code: 'CYKA' },
    { name: 'Yellowknife Airport', code: 'CYZF' },
    { name: 'Whitehorse Erik Nielsen International Airport', code: 'CYXY' },
    { name: 'Fort McMurray International Airport', code: 'CYMM' },
    { name: 'Nanaimo Airport', code: 'CYCD' },
    { name: 'Red Deer Regional Airport', code: 'CYQF' },
    { name: 'Timmins Victor M. Power Airport', code: 'CYTS' },
    { name: 'Lethbridge Airport', code: 'CYQL' },
    { name: 'Medicine Hat Airport', code: 'CYXH' },
    { name: 'North Bay Jack Garland Airport', code: 'CYYB' },
    { name: 'Penticton Regional Airport', code: 'CYYF' },
    { name: 'Prince George Airport', code: 'CYXS' },
    { name: 'Sault Ste. Marie Airport', code: 'CYAM' },
    { name: 'Sept-Îles Airport', code: 'CYZV' },
    { name: 'Terrace-Kitimat Airport', code: 'CYXT' },
    { name: 'Thunder Bay International Airport', code: 'CYQT' },
    { name: 'Val-d\'Or Airport', code: 'CYVO' },
    { name: 'Windsor International Airport', code: 'CYQG' },
    { name: 'Bagotville Airport', code: 'CYBG' },
    { name: 'Bathurst Airport', code: 'CZBF' },
    { name: 'Brandon Municipal Airport', code: 'CYBR' },
    { name: 'Chibougamau/Chapais Airport', code: 'CYMT' },
    { name: 'Churchill Airport', code: 'CYYQ' },
    { name: 'Cranbrook/Canadian Rockies International Airport', code: 'CYXC' },
    { name: 'Fort St. John Airport', code: 'CYXJ' },
    { name: 'Goose Bay Airport', code: 'CYYR' },
    { name: 'Iqaluit Airport', code: 'CYFB' },
    { name: 'Kuujjuaq Airport', code: 'CYVP' },
    { name: 'La Ronge (Barber Field) Airport', code: 'CYVC' },
    { name: 'Labrador City Airport', code: 'CYWK' },
    { name: 'Peace River Airport', code: 'CYPE' },
    { name: 'Quesnel Airport', code: 'CYQZ' },
    { name: 'Rankin Inlet Airport', code: 'CYRT' },
    { name: 'Rouyn-Noranda Airport', code: 'CYUY' },
    { name: 'Sandspit Airport', code: 'CYZP' },
    { name: 'Smithers Airport', code: 'CYYD' },
    { name: 'Thompson Airport', code: 'CYTH' },
    { name: 'Wabush Airport', code: 'CYWK' },
  ];
  

  useEffect(() => {
    if (email) {
      setFormData((prevData) => ({
        ...prevData,
        email,
        confirmEmail: email,
      }));
    }
  }, [email]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleTermsChange = (event) => {
    setAcceptedTerms(event.target.checked);
  };

  const handleFlightNumberDropdownChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value.callsign,
      ["flightNumber"]: value.callsign,
    });
  };

  const handleDateChange = (date) => {
    setFormData({
      ...formData,
      departureDate: date,
    });
  };

  const validateAirline = (flightNumber) => {
    const airlineCode = flightNumber.slice(0, 2);
    return supportedAirlines.includes(airlineCode);
  };

  const validateFlightNumber = (flightNumber) => {
    const flightNumberPattern = /^[A-Z]{2}\d{1,4}\s*$/;
    return flightNumberPattern.test(flightNumber);
  };

  const validateBookingReference = (bookingReference) => {
    const bookingReferencePattern = /^[A-Z0-9]{6}$/;
    return bookingReferencePattern.test(bookingReference);
  };

  const validateTicketNumber = (ticketNumber) => {
    const ticketNumberPattern = /^\d{13}$/;
    return ticketNumberPattern.test(ticketNumber);
  };

  const validateDate = (date) => {
    const today = new Date();
    return !date || date <= today;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!acceptedTerms) {
      setAlert({open: true, severity: 'error', message: 'You must agree to the terms and conditions.'});
      return;
    }

    if (formData.email !== formData.confirmEmail) {
      setAlert({open: true, severity: 'error', message: 'Email addresses do not match'});
      return;
    }
    if (!validateAirline(formData.flightNumber)) {
      setAlert({open: true, severity: 'error', message: 'The claim is only for Air Canada, Air Transat, Flair, Sunwings, or WestJet flights.'});
      return;
    }
    if (!validateFlightNumber(formData.flightNumber)) {
      setAlert({open: true, severity: 'error', message: 'Invalid flight number format. Expected format is two letters followed by up to four digits.'});
      return;
    }
    if (!validateDate(formData.departureDate)) {
      setAlert({open: true, severity: 'error', message: 'Invalid departure date. The date cannot be in the future.'});
      return;
    }

    setAlert({});
    setUserEmail(formData.email);
    onSubmit(formData);
  };

  const fetchFlightOptions = async () => {
    setLoadingFlights(true);
    try {
      const { departureAirport, arrivalAirport, departureDate } = formData;
      const response = await axios.get('https://opensky-network.org/api/flights/departure', {
        params: {
          airport: departureAirport.code,
          begin: Math.floor(new Date(departureDate).getTime() / 1000),
          end: Math.floor(new Date(departureDate).getTime() / 1000) + 86400, // 24 hours window
        },
      });
      const filteredFlights = response.data.filter(
        (flight) => flight.estArrivalAirport === arrivalAirport.code
      );
      setFlightOptions(filteredFlights);
    } catch (error) {
      console.error('Error fetching flight data:', error);
    } finally {
      setLoadingFlights(false);
    }
  };

  useEffect(() => {
    if (formData.departureAirport && formData.arrivalAirport && formData.departureDate) {
      fetchFlightOptions();
    }
  }, [formData.departureAirport, formData.arrivalAirport, formData.departureDate]);

  return (
    <FormContainer elevation={3}>
      <form onSubmit={handleSubmit}>
        <Typography variant="h6" gutterBottom align="center">
          Submit Claim
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField label="First Name" variant="outlined" fullWidth name="firstName" value={formData.firstName} onChange={handleChange} required />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField label="Last Name" variant="outlined" fullWidth name="lastName" value={formData.lastName} onChange={handleChange} required />
          </Grid>
          <Grid item xs={12}>
            <TextField label="Email Address Used in Booking" variant="outlined" fullWidth name="email" type="email" value={formData.email} onChange={handleChange} required />
          </Grid>
          <Grid item xs={12}>
            <TextField label="Confirm Email Address" variant="outlined" fullWidth name="confirmEmail" type="email" value={formData.confirmEmail} onChange={handleChange} required inputProps={{ onPaste: (e) => e.preventDefault(), onCopy: (e) => e.preventDefault() }}/>
          </Grid>
          <Grid item xs={12}>
            <TextField label="Ticket Number" variant="outlined" fullWidth name="ticketNumber" value={formData.ticketNumber} onChange={handleChange} required />
          </Grid>
          <Grid item xs={12}>
            <TextField label="Booking Reference" variant="outlined" fullWidth name="bookingReference" value={formData.bookingReference} onChange={handleChange} required />
          </Grid>
          <Grid item xs={12}>
            <DatePicker
              selected={formData.departureDate}
              onChange={handleDateChange}
              dateFormat="yyyy-MM-dd"
              customInput={<TextField label="Departure Date" variant="outlined" fullWidth />}
              wrapperClassName="datePickerFullWidth"
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField label="Flight Number" variant="outlined" fullWidth name="flightNumber" value={formData.flightNumber} onChange={handleChange} required/>
            <Typography variant="outlined" color="textSecondary">
              Enter the flight number directly if you know it, or use the fields below to search for it.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              options={airports}
              getOptionLabel={(option) => `${option.name} (${option.code})`}
              isOptionEqualToValue={(option, value) => option.code === value.code}
              loading={loadingAirports}
              onInputChange={(e, value) => setAirportOptions(airports.filter(airport => airport.name.toLowerCase().includes(value.toLowerCase()) || airport.code.toLowerCase().includes(value.toLowerCase())))}
              onChange={(event, value) => handleChange({ target: { name: 'departureAirport', value } })}
              renderInput={(params) => (
                <TextField {...params} label="Departure Airport" variant="outlined" fullWidth name="departureAirport" value={formData.departureAirport} />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              options={airports}
              getOptionLabel={(option) => `${option.name} (${option.code})`}
              isOptionEqualToValue={(option, value) => option.code === value.code}
              loading={loadingAirports}
              onInputChange={(e, value) => setAirportOptions(airports.filter(airport => airport.name.toLowerCase().includes(value.toLowerCase()) || airport.code.toLowerCase().includes(value.toLowerCase())))}
              onChange={(event, value) => handleChange({ target: { name: 'arrivalAirport', value } })}
              renderInput={(params) => (
                <TextField {...params} label="Arrival Airport" variant="outlined" fullWidth name="arrivalAirport" value={formData.arrivalAirport} />
              )}
            />
          </Grid>
          {formData.departureAirport && formData.arrivalAirport && formData.departureDate && (
            <Grid item xs={12}>
              <Autocomplete
                options={flightOptions}
                getOptionLabel={(option) => `${option.callsign} - ${new Date(option.firstSeen * 1000).toLocaleTimeString()} (Departure time)`}
                isOptionEqualToValue={(option, value) => option.callsign === value.callsign}
                loading={loadingFlights}
                onChange={(event, value) => handleFlightNumberDropdownChange({ target: { name: 'flightNumberDropDown', value } })}
                renderInput={(params) => (
                  <TextField {...params} label="Flight Number" variant="outlined" fullWidth name="flightNumberDropDown" value={formData.flightNumberDropDown} onChange={handleFlightNumberDropdownChange} />
                )}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox checked={acceptedTerms} onChange={handleTermsChange} name="acceptTerms" />}
              label={<Typography>I agree to the <Link href="http://localhost:3000/terms" target="_blank">Terms and Conditions</Link>.</Typography>}
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Submit Claim
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormContainer>
  );
};

export default ClaimForm;