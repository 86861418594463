import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import { Container, Typography, Button, AppBar, Toolbar, Dialog, DialogContent, DialogActions, Alert, Paper } from '@mui/material';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled } from '@mui/system';
import { Menu, MenuItem, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { Helmet } from 'react-helmet';
import CountUp from 'react-countup';
import ClaimForm from './ClaimForm';
import ClaimStatus from './ClaimStatus';
import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';
import PaymentForm from './PaymentForm';
import theme from './theme';
import './App.css';
import SupportForm from './SupportForm';
import logo from './logo.png';
import CustomAlert from './CustomAlert';
import airplane from './airplane.png';
import neuralnet from './neuralnet.png';

const stripePromise = loadStripe('pk_live_51PLl3B09WfeubqeomRba7McZvaWFRTOw9QZmwvHG0XcHEnZkmwoUEcEL7T06solfWehSxkkpcYGlITUJRcxfPecL00ml6yaNVn');



const FormContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginTop: theme.spacing(3),
  borderRadius: theme.spacing(3),
  backgroundColor: theme.palette.background.paper,
  width: '80vw', // Adjust the width to 50% of the viewport width
  textAlign: 'center'
}));

const StyledContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '20px',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

const FormContainerNotCentered = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginTop: theme.spacing(3),
  borderRadius: theme.spacing(3),
  backgroundColor: theme.palette.background.paper,
  width: '80vw', // Adjust the width to 50% of the viewport width
}));





const ResponsiveAppBar = ({ logo, isLoggedIn, navigate, setIsLoggedIn, handleLoginOpen, handleRegisterOpen }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  return (
    <AppBar position="static" style={{ width: '100%' }}>
      <Toolbar style={{ display: 'flex', justifyContent: 'space-between', padding: isSmallScreen ? '0 10px' : '0 20px' }}>
        <img
          src={logo}
          alt="FlyClaim Logo - Your trusted partner in flight compensation claims"
          style={{ height: isSmallScreen ? '30px' : '40px', marginRight: '10px' }}
        />
        {!isSmallScreen && (
          <Typography variant="h6" style={{ flexGrow: 1, textAlign: 'left', marginLeft: '10px', marginRight: '20px', fontSize: isSmallScreen ? '1rem' : '1.25rem' }}>
            FlyClaim
          </Typography>
        )}
        {isSmallScreen ? (
          <>
            <IconButton color="inherit" onClick={handleMenuOpen}>
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={menuAnchorEl}
              open={Boolean(menuAnchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={() => { navigate('/'); handleMenuClose(); }}>Claim Form</MenuItem>
              {isLoggedIn ? (
                <>
                  <MenuItem onClick={() => { navigate('/status'); handleMenuClose(); }}>Claim Status</MenuItem>
                  <MenuItem onClick={() => { navigate('/support'); handleMenuClose(); }}>Support</MenuItem>
                  <MenuItem onClick={() => { navigate('/terms'); handleMenuClose(); }}>Terms and Conditions</MenuItem>
                  <MenuItem onClick={() => {
                    setIsLoggedIn(false);
                    localStorage.removeItem('token');
                    localStorage.removeItem('email');
                    handleMenuClose();
                  }}>Logout</MenuItem>
                </>
              ) : (
                <>
                  <MenuItem onClick={() => { navigate('/our-mission'); handleMenuClose(); }}>Our Mission</MenuItem>
                  <MenuItem onClick={() => { navigate('/terms'); handleMenuClose(); }}>Terms and Conditions</MenuItem>
                </>
              )}
            </Menu>
            {!isLoggedIn && (
              <>
                <Button color="inherit" onClick={handleLoginOpen} style={{ fontSize: '0.75rem' }}>
                  Login
                </Button>
                <Button color="inherit" onClick={handleRegisterOpen} style={{ fontSize: '0.75rem' }}>
                  Register
                </Button>
              </>
            )}
          </>
        ) : (
          <div style={{ display: 'flex', gap: '10px' }}>
            {isLoggedIn ? (
              <>
                <Button color="inherit" onClick={() => navigate('/status')} style={{ fontSize: '1rem' }}>
                  Claim Status
                </Button>
                <Button color="inherit" onClick={() => navigate('/')} style={{ fontSize: '1rem' }}>
                  Claim Form
                </Button>
                <Button color="inherit" onClick={() => navigate('/support')} style={{ fontSize: '1rem' }}>
                  Support
                </Button>
                <Button color="inherit" onClick={() => navigate('/terms')} style={{ fontSize: '1rem' }}>
                  Terms and Conditions
                </Button>
                <Button
                  color="inherit"
                  onClick={() => {
                    setIsLoggedIn(false);
                    localStorage.removeItem('token');
                    localStorage.removeItem('email');
                  }}
                  style={{ fontSize: '1rem' }}
                >
                  Logout
                </Button>
              </>
            ) : (
              <>
                <Button color="inherit" onClick={() => navigate('/')} style={{ fontSize: '1rem' }}>
                  Claim Form
                </Button>
                <Button color="inherit" onClick={() => navigate('/our-mission')} style={{ fontSize: '1rem' }}>
                  Our Mission
                </Button>
                <Button color="inherit" onClick={() => navigate('/terms')} style={{ fontSize: '1rem' }}>
                  Terms and Conditions
                </Button>
                <Button color="inherit" onClick={handleLoginOpen} style={{ fontSize: '1rem' }}>
                  Login
                </Button>
                <Button color="inherit" onClick={handleRegisterOpen} style={{ fontSize: '1rem' }}>
                  Register
                </Button>
              </>
            )}
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
};




const FeesCounter = () => (
  <FormContainer as="section">
  
  <Typography variant="h1" gutterBottom style={{ fontSize: '1.5rem' }}> 
    Flight Delay and Cancellation Compensation Claims
  </Typography>
  <Typography variant="h5" gutterBottom>Understanding Your Rights</Typography>
  <Typography variant="body1" gutterBottom>
  <strong>Did you know?</strong> As a Canadian air passenger, you have rights protected under the <a href="https://www.otc-cta.gc.ca/eng/air-passenger-protection-regulations" target="_blank" rel="noopener noreferrer"><strong>Canadian Air Passenger Protection Regulations</strong></a>. Specifically, these regulations state that passengers are entitled to compensation for flight delays, cancellations, and overbookings. <strong>Section 19</strong> of these regulations highlights, "<strong>Air passengers are eligible for compensation up to $1,000 for delays and cancellations within the carrier's control that are not required for safety.</strong>"

  <br /><br />

  <strong>Learn how you can claim what you deserve with FlyClaim.</strong> Many passengers are not aware of these rights, and less than 9% of eligible travelers claim compensation each year. This lack of claims results in over $75 million in unclaimed compensations annually. By enforcing these rights, we can push for more punctuality and accountability from airlines, making air travel better for everyone.

  <br /><br />

  <strong>We're here to help:</strong> Our experienced Canadian legal team specializes in navigating these complex regulations in Canada to secure the compensation you're owed. With FlyClaim, asserting your rights is straightforward and stress-free. Join thousands of Canadians who have successfully claimed their rightful compensation with our assistance.

  <br /><br />

  <strong>We understand the unique challenges Canadian travelers face and are committed to making your voices heard.</strong> Currently, we serve passengers who travelled with Air Canada, WestJet, Air Transat, Flair Airlines, and Sunwings, and we are working hard to expand coverage to all other Canadian airlines. Together, we can hold airlines accountable and improve air travel for all Canadians.
</Typography>
    <Typography variant="h4">
      We will only charge commission if we win.
    </Typography>
    <Typography variant="h4">
      We will only charge legal fees if legal action is necessary.
    </Typography>
    <Typography variant="h4" style={{ color: '#f13f82', textDecoration: 'line-through' }}>
      Industry-Standard Service Fee: <span> </span><CountUp start={100} end={35} duration={1} suffix="%" />
    </Typography>
    <Typography variant="h4" style={{ color: '#f13f82', textDecoration: 'line-through' }}>
      Industry-Standard Legal Fee: <span> </span><CountUp start={100} end={15} duration={1} suffix="%" />
    </Typography>
    <Typography variant="h4">
      Our Service Fee: 
      <span> </span><CountUp start={100} end={18} duration={3} suffix="%" />
    </Typography>
    <Typography variant="h4">
      Our Legal Fee: 
      <span> </span><CountUp start={100} end={12} duration={3} suffix="%" />
    </Typography>
  </FormContainer>
);

const OurMission = () => (
  <FormContainerNotCentered as="section">
    <Typography variant="h6">Our Mission</Typography>
    <br />
    <Typography variant="body1">
      <strong>Origins</strong><br />
      FlyClaim was born out of a shared vision among a group of passionate Canadian travelers who experienced the frustration and inconvenience of delayed and cancelled flights firsthand. Recognizing a gap in the market, we set out to create a service that not only simplifies the flight booking process but also ensures passengers are fairly compensated for delays and cancellations, without the hassle of filing claims. Our journey began with a simple idea: to transform the way people travel by making every aspect of their journey smoother and more reliable.<br /><br />
      <strong>Mission</strong><br />
      At FlyClaim, our mission is to revolutionize the flight booking experience by seamlessly integrating modern technology with exceptional customer service. We are committed to providing travelers with a hassle-free and efficient way to book flights, ensuring that every ticket comes with an automatic compensation claim for any delays and cancellations. We believe that travel should be an enjoyable and stress-free experience, and we strive to make that a reality for every passenger.<br /><br />
      <strong>Values</strong>
      <ol>
        <li><strong>Customer-Centric</strong>: Our customers are at the heart of everything we do. We prioritize their needs and continuously seek to exceed their expectations.</li>
        <li><strong>Transparent</strong>: We believe in clear communication and honesty. Our processes are straightforward, and we ensure that our customers are always informed and empowered.</li>
        <li><strong>Innovative</strong>: We embrace the latest technologies and innovations to enhance our services, making travel easier and more enjoyable.</li>
        <li><strong>Reliable</strong>: Trust and dependability are the cornerstones of our service. We are dedicated to providing consistent and reliable support to our customers.</li>
        <li><strong>Empathetic</strong>: We understand the challenges and frustrations that come with travel disruptions. Our empathetic approach ensures that our customers feel heard, valued, and supported.</li>
      </ol>
      <br />
      <strong>Goal</strong><br />
      Our goal at FlyClaim is to deliver a modern and seamless flight booking experience that goes beyond expectations. In the future, we aim to make every ticket an "auto-claim" ticket, where passengers are automatically compensated for any delays and cancellations, without charging any fees. By doing so, we not only simplify the travel process but also bring peace of mind to our customers, knowing that they are protected and valued.<br /><br />
      To reach this ambitious goal, we start by assisting Canadian passengers with the compensation claim process. We aim to secure deserved compensation as swiftly and efficiently as possible, minimizing the steps involved.<br /><br />
      At FlyClaim, we are not just a flight booking service; we are your travel partner, dedicated to making your journey as enjoyable and worry-free as possible. Join us in experiencing a new era of travel where convenience and care come together to create unforgettable journeys.
    </Typography>
    <br />
    <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '20px' }}>
  <img src={airplane} alt="Airplane" style={{ maxWidth: '47%' }} />
  <img src={neuralnet} alt="Neural Network" style={{ maxWidth: '47%' }} />
</div>
        </FormContainerNotCentered>
      );

const TermsAndConditions = () => (
  <FormContainerNotCentered as="section">
    

    <Typography variant="h4" component="h1" gutterBottom>
      Terms and Conditions
    </Typography>
    <Typography paragraph>
      These <strong>Terms and Conditions</strong> ("<strong>Terms</strong>") establish a legally binding agreement between you ("<strong>Customer</strong>" or "<strong>you</strong>") and FlyClaim regarding our Services. By agreeing to these Terms, you commit to comply with them and any additional terms applicable to the Services you choose.
    </Typography>
    <Typography paragraph>
      We recommend not utilizing FlyClaim's Services until you have completely comprehended these Terms. For any questions, concerns, or clarifications, please contact us at admin@flyclaim.ca.
    </Typography>
    <Typography variant="h5" gutterBottom>
      1. USE AND NATURE OF OUR SERVICES
    </Typography>
    <Typography variant="h6" gutterBottom>
      Utilization of Our Services
    </Typography>
    <Typography paragraph>
      By accepting these Terms and using our Services, you affirm that you are:
      <ol>
        <li>A natural person of legal age in your jurisdiction, capable of entering enforceable contracts under applicable laws.</li>
        <li>Authorized to accept these Terms and any service-specific terms either for yourself or on behalf of other passengers, as applicable.</li>
      </ol>
    </Typography>
    <Typography variant="h6" gutterBottom>
      Nature of Our Services
    </Typography>
    <Typography paragraph>
      The FlyClaim Group provides services designed to assist air travelers in managing flight disruptions. These services, which are subject to change, primarily involve processing claims against Airlines to uphold air passenger rights such as compensation claims for delays, cancellations, and other similar disruptions.
    </Typography>
    <Typography paragraph>
      FlyClaim is not a law firm and does not offer direct legal representation. Nevertheless, FlyClaim may engage contract Lawyers or attorneys during service delivery in accordance with the Terms and Forms to support our Services.
    </Typography>
    <Typography variant="h5" gutterBottom>
      2. DEFINITIONS
    </Typography>
    <Typography paragraph>
      In these Terms, the following definitions are used:
      <ol>
        <li><strong>FlyClaim Third Party</strong> means any individual or entity that FlyClaim contracts to help directly or indirectly in providing our Services to the Customer.</li>
        <li><strong>Airline</strong> means the commercial airline responsible for operating the flight covered by our Services.</li>
        <li><strong>Air Passenger Rights Regulations</strong> includes laws, regulations, directives, international conventions, or other legislations at various governmental levels, and case law, which support claims for monetary compensation, damages, or refunds due to overbooked, delayed, or canceled flights, or baggage issues. This includes, among others, EC 261, UK 261, SHY, Canadian Transportation Act: Air Passenger Protection Regulations, and MC 99.</li>
        <li><strong>Assignment Agreement</strong> is a document, electronic or otherwise, through which you and FlyClaim agree that FlyClaim will own the Claim for the purpose of collection and receipt of payments, as outlined in the Assignment Agreement.</li>
        <li><strong>Authority Document</strong> is a document that authorizes FlyClaim or a FlyClaim Third Party to represent you regarding your Claim. This document varies based on different jurisdictional requirements and may include powers of attorney or certificates of authorization.</li>
        <li><strong>Claim</strong> refers to any claim against an Airline for monetary compensation, damages, or refunds under Air Passenger Rights Laws or an Airline's goodwill policies.</li>
        <li><strong>Compensation Service</strong> is the service where FlyClaim, either alone or with its Group Companies and FlyClaim Third Parties, pursues a Claim on your behalf, potentially through Legal Action.</li>
        <li><strong>Customer</strong> or <strong>Client</strong> means you, the individual who agrees to these Terms.</li>
        <li><strong>Discretion</strong> refers to FlyClaim's authority to make decisions about your Claim based on data, detailed further in section 3: How FlyClaim Makes Decisions Regarding Your Claim.</li>
        <li><strong>Eligible Claim</strong> is a Claim that FlyClaim, in its sole Discretion, considers appropriate to pursue under the Compensation Service.</li>
        <li><strong>Flight Compensation</strong> includes all payments made by an Airline in relation to a Claim as compensation, refunds, damages, settlements, gestures of goodwill, or otherwise to you or FlyClaim due to the Compensation Service. This excludes any payments for legal expenses, collection costs, interest, or similar pre-financed or incurred by FlyClaim.</li>
        <li><strong>Fellow Passenger</strong> refers to any other passenger included by the Customer in the Form for the receipt of Flight Compensation, such as family members or co-passengers.</li>
        <li><strong>Form</strong> includes the Claim Form, Authority Document, power of attorney, or other necessary documents that enable the FlyClaim Group and FlyClaim Third Parties to deliver our Services to you. FlyClaim determines the appropriate Form for each Claim.</li>
        <li><strong>Goodwill Claim</strong> is a request to an Airline for Flight Compensation in the absence of established Air Passenger Rights Regulations, which may include monetary or non-monetary forms like flight vouchers or air miles.</li>
        <li><strong>Information Service</strong> provides flight, airline, airport, and other travel-related information, along with details on air passenger rights under applicable Air Passenger Rights Regulations, delivered through electronic means such as email, personalized dashboards, or FlyClaim-controlled websites.</li>
        <li><strong>Lawyer</strong> includes any attorney or law firm contracted by FlyClaim for Legal Action or other legal matters related to a Claim.</li>
        <li><strong>Legal Action</strong> involves the preparation and filing of a Claim for judicial resolution, whether through court proceedings or before government bodies, or by delegating the Claim to a Lawyer for direct presentation to an Airline or for judicial proceedings.</li>
        <li><strong>Legal Action Fee</strong> is a fee listed in Section 4, charged to you upon successful Flight Compensation involving Legal Action, in addition to the Service Fee.</li>
        <li><strong>Legal Expenses</strong> cover all costs related to Legal Action, including attorney fees, application fees, and other related legal and administrative expenses.</li>
        <li><strong>Our Fees</strong> are fees and charges, detailed in Section 4, payable by you to FlyClaim for successful Flight Compensation, excluding any costs covered directly by an Airline as described in these Terms.</li>
        <li><strong>Reassignment Form</strong> is the document signed by you and FlyClaim to transfer the rights of your Claim back to you or to authorize you again to claim your rights.</li>
        <li><strong>Service Fee</strong> is the fee, as detailed in Section 4, charged by FlyClaim when you receive Flight Compensation following the Compensation Service.</li>
        <li><strong>Services</strong> refers to all services offered by FlyClaim or a FlyClaim Third Party under these Terms and any specific additional terms selected by you.</li>
        <li><strong>VAT</strong> refers to any applicable value-added tax system.</li>
      </ol>
    </Typography>
    <Typography variant="h5" gutterBottom>
      3. COMPENSATION SERVICE
    </Typography>
    <Typography paragraph>
      Before initiating the Compensation Service, you must:
      <ol>
        <li>Check the "I agree to the Terms and Conditions" box, acknowledging your agreement to receive the Compensation Service and recognizing that FlyClaim will claim only monetary Flight Compensation. FlyClaim holds the Discretion to accept non-monetary forms of Flight Compensation from the Airline, which may be considered as the Airline's refusal to provide Flight Compensation.</li>
        <li>Affirm that you are not pursuing the Claim through other means, either directly or via third parties, and that no legal dispute is pending or expected between you and the Airline on the same matter.</li>
      </ol>
      Existing engagements or assignments regarding your Claim must be cancelled immediately. Failure to complete these steps will result in non-provision of the Compensation Service.
    </Typography>
    <Typography paragraph>
      Upon reviewing the Form, FlyClaim will confirm on the website that the Compensation Service will commence. FlyClaim may decide at its Discretion to proceed with, reject, or request additional information or documentation before starting the Compensation Service.
    </Typography>
    <Typography paragraph>
      After submitting the Form, you must:
      <ol>
        <li>Refrain from assigning the Claim to others since the legal title has been assigned to FlyClaim.</li>
        <li>Not authorize or mandate another third party to act concerning the same Claim.</li>
        <li>Immediately notify FlyClaim of any direct payments or other compensation from the Airline after entering into the compensation agreement. Such payments will be considered Flight Compensation, entitling FlyClaim to the Service Fee and Legal Action Fee if Legal Action has occurred prior to the payment from the Airline.</li>
      </ol>
      All negotiations with the Airline should cease, and any communications should be directed to FlyClaim.
    </Typography>
    <Typography paragraph>
      <strong>Customer Representative</strong>
      <br></br>
      If acting as a representative for other passengers, you must obtain and produce a power of attorney upon request by FlyClaim. For minor passengers, complete data including names, birthdates, and custodian or legal guardian signatures must be provided.
    </Typography>
    <Typography paragraph>
      <strong>Commencing the Compensation Services</strong>
      <br></br>
      Upon notification from FlyClaim as previously mentioned, FlyClaim will commence the Compensation Service aiming to secure Flight Compensation on your behalf.
    </Typography>
    <Typography paragraph>
      You must provide all necessary data and information for executing the Compensation Service. You warrant that the information you provide is accurate, complete, true, and where applicable, provided with the consent of Fellow Passengers. Flight data and information may be submitted via the website, email, supported electronic or software solutions.
    </Typography>
    <Typography paragraph>
      FlyClaim may require additional information such as:
      <ul>
        <li>Passenger name, surname, email address, phone number, type of disruption (delay, cancelled flight, or denied boarding/overbooking), delay reason (if known), delay length, Airline name, flight number, flight date, departure and arrival places, and incident description.</li>
        <li>An additional Form which may be sent through various means supported by FlyClaim.</li>
      </ul>
      Failure to provide necessary information will adversely affect FlyClaim's ability to deliver the Compensation Service.
    </Typography>
    <Typography paragraph>
      <strong>Flight Compensation and Legal Action</strong>

      <br></br>
      Upon receiving your Form, FlyClaim will prepare and send a payment request to the Airline, handling all subsequent correspondence. If Flight Compensation is paid, you are obliged to pay the Service Fee to FlyClaim.
    </Typography>
    <Typography paragraph>
      If the Airline does not pay within a reasonable period, or if FlyClaim assesses that the Airline is unlikely to respond, FlyClaim may initiate Legal Action. Should Legal Action result in payment, you must pay the Legal Action Fee in addition to the Service Fee.
    </Typography>
    <Typography paragraph>
      If a Lawyer is employed for Legal Action, you expressly agree that FlyClaim may grant the Lawyer access to all data you provided to enable the Legal Action.
    </Typography>
    <Typography paragraph>
      Should additional documents be required for Legal Action, you commit to providing these. In cases where you have signed an Assignment Agreement and later an Authority Document (or similar), you accept that the Authority Document reassigns the Claim back to you.
    </Typography>
    <Typography paragraph>
      If FlyClaim or the Lawyer initiates Legal Action to pursue a Claim, FlyClaim will cover any Legal Expenses incurred, even if the Legal Action is unsuccessful. If the lawsuit is won or a settlement is reached with the Airline, FlyClaim may cover costs not reimbursed by the Airline. Reimbursement claims for Legal Expenses will be made if applicable, and all reimbursements, including Legal Expenses and interest, belong to FlyClaim.
    </Typography>
    <Typography paragraph>
      You acknowledge that resolving Legal Action and securing Flight Compensation may take years.
    </Typography>
    <Typography paragraph>
      <strong>Settlements</strong>

      <br></br>
      FlyClaim, at its sole Discretion, may negotiate individual or collective Claim settlements for Flight Compensation with the Airline. You recognize that the decision to accept any settlement offer rests solely with FlyClaim since you have assigned the Claim to FlyClaim. If FlyClaim acts on your behalf under an Authority Document, you authorize FlyClaim to accept or reject settlement offers based on its Discretion without needing additional consent from you.
    </Typography>
    <Typography paragraph>
      FlyClaim may pursue the assigned Claim in its name, with or without the assistance of a Lawyer. Where legally required or appropriate, FlyClaim may reassign the Claim to you, and you agree to reauthorize FlyClaim with an Authority Document to deliver the Compensation Service and collect and/or receive payments.
    </Typography>
    <Typography paragraph>
      You are responsible for providing accurate data to proceed with the Compensation Service. FlyClaim is not liable for any incorrect communications, documents, or fraudulent conduct provided by you. If you deliberately provide incorrect information or data, in addition to other recourse available under these Terms, you must reimburse FlyClaim for Legal Expenses upon request.
    </Typography>
    <Typography paragraph>
      <strong>Goodwill Claims</strong>
      <br></br>
      In jurisdictions with less developed Air Passenger Rights Regulations, FlyClaim may attempt to obtain Flight Compensation through a Goodwill Claim upon your decision to proceed.
    </Typography>
    <Typography paragraph>
      <strong>How FlyClaim Makes Decisions Regarding Your Claim</strong>
      <br></br>
      FlyClaim exercises its Discretion in deciding whether to pursue your Claim at any point during the Compensation Service. This includes decisions on pursuing Legal Action, accepting or making settlement offers, withdrawing the Claim, or pursuing a Goodwill Claim.
    </Typography>
    <Typography paragraph>
      Factors influencing FlyClaim's decisions include applicable Air Passenger Rights Regulations, the jurisdictions and court systems where the Claim may be pursued, potential Legal Expenses and the likelihood of incurring or receiving them, the Airline's historical behavior with similar Claims, the expected duration to secure Flight Compensation or a favorable ruling through Legal Action, the quality of information you provide, factual issues that may complicate obtaining Flight Compensation, advice from Lawyers, and FlyClaim's experience with similar Claims.
    </Typography>
    <Typography variant="h5" gutterBottom>
      4. FEES AND PAYMENTS
    </Typography>
    <Typography paragraph>
      <strong>Our Fees</strong>
      <br></br>
      FlyClaim offers the Compensation Service free of charge unless successful in obtaining Flight Compensation for you. If successful, FlyClaim will charge an 18% Service Fee of the Flight Compensation and transfer the remaining amount to you, subject to Our Fees.
    </Typography>
    <Typography paragraph>
      If Legal Action is undertaken and successful, an additional 12% Legal Action Fee will be charged.
    </Typography>
    <Typography paragraph>
      If incorrect or incomplete payment information is provided by you, and the Flight Compensation payments are returned to FlyClaim, FlyClaim will make reasonable efforts to contact you for correct payment information. If you fail to respond, FlyClaim is entitled to keep the portion of the Flight Compensation that should have been transferred to you.
    </Typography>
    <Typography paragraph>
      If the Flight Compensation and/or Legal Expenses, interest, or similar are transferred directly from the Airline to you, you must:
      <ul>
        <li>Notify FlyClaim of the payment as soon as reasonably practicable.</li>
        <li>Be invoiced and liable to pay Our Fees, Legal Expenses, interest, or similar to FlyClaim without unreasonable delay.</li>
      </ul>
    </Typography>
    <Typography paragraph>
      Once FlyClaim has paid the Flight Compensation according to your selected method, FlyClaim is not liable for:
      <ul>
        <li>Checks, prepaid debit cards, credit cards, and similar lost in transit to you;</li>
        <li>Any errors due to you providing incorrect bank account information, wrong address, or similar, including but not limited to, Flight Compensation being paid to the wrong receiver. If Flight Compensation is paid to the wrong receiver due to your fault, FlyClaim is not obligated to actively reclaim it.</li>
      </ul>
    </Typography>
    <Typography paragraph>
      You are not entitled to claim any interest on the Flight Compensation during the period between when FlyClaim receives it and when it is transferred to you. FlyClaim reserves the right to retain any interest it recovers from the Airline, including interest recovered following Legal Action.
    </Typography>
    <Typography paragraph>
      FlyClaim is not liable for any amount of compensation, damages, or similar if it is prevented from transferring the payment to you due to events beyond FlyClaim’s control, including, but not limited to, strikes, lockouts, labour disputes, force majeure, natural disasters, wars, riots, civil commotions, malicious damage, compliance with laws or governmental orders, rules, regulations or directions, accidents, breakdowns of plant or machinery, fires, floods, and storms.
    </Typography>
    <Typography paragraph>
      The Service Fee and the Legal Action Fee apply to any kind of settlement whenever FlyClaim is able to obtain Flight Compensation, whether obtained through a court decision, settlement with the Airline, or where FlyClaim has settled the Claim with the Airline jointly with other Claims.
    </Typography>
    <Typography variant="h5" gutterBottom>
      5. PRIVACY POLICY
    </Typography>
    <Typography paragraph>
      We regard data privacy as a core aspect of our operations. Our Privacy Statement and practices are committed to handling personal data appropriately and lawfully, ensuring confidentiality, integrity, and availability.
    </Typography>
    <Typography paragraph>
      This Privacy Statement applies to www.flyclaim.ca. For the purposes of this statement, "FlyClaim", "we", "us", or "our" may refer to the entire company group or individual companies within the group.
    </Typography>
    <Typography paragraph>
      Our Privacy Statement is the comprehensive online privacy policy applicable to our activities. It details the types of information we collect, how we use, share, and secure your information, and your rights concerning the use, access, and correction of your personal data.
    </Typography>
    <Typography paragraph>
      Your personal data is processed in compliance with applicable data privacy and data protection laws ("Data Protection Laws"), which may include, depending on the circumstances, the General Data Protection Regulation (EU) 2016/679 ("GDPR"), the Brazilian General Data Protection Law (Lei Geral de Proteção de Dados or "LGPD"), and other relevant laws and regulations.
    </Typography>
    <Typography paragraph>
      FlyClaim is designated as the data controller, responsible for determining the purposes and means of processing personal data.
    </Typography>
    <Typography paragraph>
      <strong>Personal Data</strong>
      <br></br>
      Personal data refers to any information that identifies or can identify a natural person. This includes information that directly or indirectly identifies an individual, such as names, birth dates, addresses, email addresses, telephone numbers, etc.
    </Typography>
    <Typography paragraph>
      <strong>Principles</strong>
      <br></br>
      Our data protection principles are:
      <ol>
        <li>Processing personal data in a lawful, fair, and transparent manner.</li>
        <li>Collecting personal data only for specified, explicit, and legitimate purposes, and not processing it in a way incompatible with those purposes.</li>
        <li>Ensuring that the collection of personal data is adequate, relevant, and limited to what is necessary for the purposes for which it is processed.</li>
        <li>Maintaining personal data accuracy and updating it as necessary.</li>
        <li>Taking every reasonable step to ensure that inaccurate personal data is erased or rectified without delay.</li>
        <li>Keeping personal data in a form that allows for identification only as long as necessary for the processing purposes.</li>
        <li>Ensuring the confidentiality and security of all personal data.</li>
        <li>Not sharing personal data with third parties unless necessary for the provision of FlyClaim services as per the agreement.</li>
      </ol>
    </Typography>
    <Typography paragraph>
      <strong>Collection and Use of Personal Data</strong>
      <br></br>
      If you engage with our services and provide information to us, you may be asked to supply personal data necessary for the operation and improvement of our business and services. Personal data may be collected through our website, email, other electronic or software solutions, postal service, or phone, and is gathered in accordance with applicable Data Protection Laws. We will process personal data only to the extent required for specified, explicit, and legitimate purposes or as required by law in the regions where we operate.
    </Typography>
    <Typography paragraph>
      We primarily collect personal data such as names, dates of birth, addresses, email addresses, telephone numbers, passports/IDs, and national identification numbers for the purpose of delivering our Compensation Service, and other Services in accordance with the agreement. This activity is central to FlyClaim's business operations.
    </Typography>
    <Typography paragraph>
      Additionally, we collect personal data for purposes such as statistics, administration and communication, IT and security administration, physical security, authentication and authorization systems, support systems, collaboration on internal projects, and organisational activities.
    </Typography>
    <Typography paragraph>
      We obtain flight data from third parties, which includes information about delayed or cancelled flights within a specified period. This data is non-personal and is used to inform about the likelihood of having an eligible Claim. We provide our Compensation Service for Eligible Claims upon request.
    </Typography>
    <Typography paragraph>
      If you share personal data of Fellow Passengers with us, you must ensure that all Fellow Passengers have explicitly consented to their data being shared with our organisation, in compliance with data protection regulations. Furthermore, you are required to provide Fellow Passengers with access to our privacy policy to ensure they are fully informed about the processing of their data by us.
    </Typography>
    <Typography paragraph>
      We may request additional information or documents related to Fellow Passengers. If you or a Fellow Passenger fail to provide such information and/or documents, we will be unable to offer our services to that Fellow Passenger, necessitating the removal of their personal data from our databases, and preventing us from providing them with our Services.
    </Typography>
    <Typography paragraph>
      <strong>Use of Personal Data</strong>
      <br></br>
      We use personal data for the purpose for which it is collected, retaining the data only as long as necessary for that purpose. We may keep your information for as long as your account is active or as needed to provide services, comply with our legal obligations, or for any of the purposes listed above. Access to personal data is strictly limited to personnel of FlyClaim and its controlled subsidiaries and affiliates who have the appropriate authorization under a corporate binding agreement with FlyClaim and a clear business need for the data.
    </Typography>
    <Typography paragraph>
      <strong>Automated Processes and Profiling</strong>
      <br></br>
      FlyClaim does not rely on automated processing as described in article 22, paragraph 1 of the GDPR, and your personal data will not be used for profiling purposes unless explicitly communicated otherwise and only with your separate consent.
    </Typography>
    <Typography paragraph>
      However, we may use technology from us and our partners to automate certain processes, providing faster and better Services to you. Our teams regularly review these automated processes to ensure they do not negatively or unjustifiably impact you, addressing both legacy and future processes.
    </Typography>
    <Typography paragraph>
      Questions regarding your data and automated processes can be directed to admin@flyclaim.ca.
    </Typography>
    <Typography paragraph>
      <strong>Sharing of Personal Data</strong>
      <br></br>
      We only transfer personal data to third parties under the following conditions:
      <ol>
        <li>If you have given your consent.</li>
        <li>If the transfer is necessary for purposes directly related to the original purpose for which the personal data was collected.</li>
        <li>If it is necessary for the preparation, negotiation, and fulfilment of the agreement with you.</li>
        <li>If required by legal obligation, administrative or court order.</li>
        <li>If necessary for the establishment or protection of legal claims or in defence of court actions.</li>
        <li>If required for responding to lawful requests by public authorities, including meeting national security or law enforcement requirements.</li>
        <li>If it serves to prevent misuse or other illegal activities, such as deliberate attacks, to ensure data security.</li>
      </ol>
    </Typography>
    <Typography paragraph>
      We occasionally partner with other companies and business partners, both within and outside the European Economic Area, to work on our behalf, such as legal representatives to take Legal Action, or technology companies to enhance our products and services. We share necessary information with these partners, and before sharing personal information, we enter into written agreements with the recipients containing data protection terms that safeguard your data according to relevant Data Protection Laws.
    </Typography>
    <Typography paragraph>
      Service providers are only permitted to obtain the personal data they need to deliver their service. We do not disclose personal data to third parties for the purpose of allowing them to market their products or services to you. If you do not want us to share your personal data with these companies, please contact admin@flyclaim.ca.
    </Typography>
    <Typography paragraph>
      <strong>Security of Processing</strong>
      <br></br>
      The security of your personal data is important to us. We process personal data securely, applying and maintaining appropriate and generally accepted standards of technical and organisational measures to protect personal data against accidental or unlawful destruction, accidental loss, alteration, unauthorised disclosure or access, particularly where the processing involves the transmission of data over a network, and against all other unlawful forms of processing. Questions about the security of personal data can be directed to admin@flyclaim.ca.
    </Typography>
    <Typography paragraph>
      <strong>Your Data Protection Rights</strong>
      <br></br>
      You have the right to request access to and rectification or erasure of personal data, restriction of processing, or to object to processing as well as the right of data portability at any time. To help us keep your personal data updated, we advise you to inform us of any changes or discrepancies. To view and/or edit personal data, or receive information on how long we intend to retain personal data or other questions related to access to personal data, or if you would like to request that we provide you with information about whether we hold, or process on behalf of a third party, any of your personal data, please contact admin@flyclaim.ca. We will respond to your request within a reasonable timeframe.
    </Typography>
    <Typography paragraph>
      <strong>Responsibility</strong>
      <br></br>
      The Privacy Statement is under the responsibility of our Legal Team, who have the overall responsibility to ensure compliance. We ensure compliance with the Privacy Statement on a daily basis and are involved in all issues related to the protection of personal data.
    </Typography>
    <Typography paragraph>
      We are responsible for and will always be able to demonstrate compliance with the Data Protection Laws as well as our principles set out in this Privacy Statement. We shall maintain records of processing activities under our responsibility containing the information required by the Data Protection Laws and, where applicable, make the records available to the supervisory authority on request.
    </Typography>
    <Typography paragraph>
      Any inquiries concerning this Privacy Statement can be directed to admin@flyclaim.ca.
    </Typography>
    <Typography paragraph>
      <strong>Complaint</strong>
      <br></br>
      You have the right to file a complaint concerning our processing of your personal data. All queries and complaints shall be handled in a timely manner by us in accordance with internal procedures. Complaints can be submitted to admin@flyclaim.ca.
    </Typography>
    <Typography paragraph>
      In the unlikely event that you consider that our processing of your personal data infringes any Data Protection Laws, you may also lodge a complaint with a relevant supervisory authority.
    </Typography>
    <Typography paragraph>
      <strong>Changes to this Privacy Statement</strong>
      <br></br>
      This Privacy Statement may be updated from time to time, e.g., due to modifications of relevant legislation or changes to our corporate structure. If any material changes are made, you will be notified by email or by means of notice on the website prior to the change becoming effective. We encourage you to periodically review this page for the latest information on our privacy practices.
    </Typography>
    <Typography variant="h5" gutterBottom>
      6. GENERAL PROVISIONS
    </Typography>
    <Typography paragraph>
      <strong>Third Parties Services</strong>
      <br></br>
      Our Services may contain links to third-party providers’ websites or interfaces to book services via third parties. Such websites/services are controlled by independent parties over which FlyClaim has no control. Although our Services may provide information about or access to third-party suppliers and facilitate use of these third-party providers, such Services do not constitute any sponsorship, affiliation, or endorsement of these third-party providers.
    </Typography>
    <Typography paragraph>
      We remind you that by accessing third-party websites/services or using third-party services made available through our Services, you agree to be bound by these third parties’ policies and terms and conditions. We encourage you to review these terms and conditions and policies and to use them at your own risk.
    </Typography>
    <Typography paragraph>
      You hereby acknowledge and agree that FlyClaim will not be a party to any agreement entered with any such third party and will not be responsible for any errors or omissions in such third parties’ services or for any direct or indirect claim, breach, loss or damage caused or allegedly caused by or in connection with the access or use of such third parties’ services.
    </Typography>
    <Typography paragraph>
      <strong>Disclaimer of Warranties and Limitation of Liability</strong>
      <br></br>
      FlyClaim has many sources of information and data that make up the information and services available under these Terms. Although FlyClaim tries to provide accurate information, our Services may be subject to omissions and errors. Our Services are provided ‘as is’. To the fullest extent permitted by law, FlyClaim disclaims all warranties, either express or implied, statutory or otherwise, including but not limited to the implied warranties of merchantability, non-infringement of third party’s rights, accuracy of information, and fitness for a particular purpose. FlyClaim makes no representations or warranties that our Services will result in Flight Compensation, meet your requirements or be secure, free of viruses or other harmful components, timely, uninterrupted, accurate, complete, or reliable. You acknowledge and agree that your use of our Services is at your own risk. To the fullest extent permitted by applicable law, FlyClaim and its Group Companies will not be liable for any direct, indirect, punitive, incidental, special, consequential losses or damages, or any loss of income, profits, goodwill arising out of or in any way related to i) our Services, ii) delay or inability to access our Services; iii) virus, bugs, trojan horses, software, information, third parties’ linked websites, services obtained through the use of our Services, iv) or any other matter relating to our Services, whether based on contract, tort, strict liability, or otherwise, even if FlyClaim, its Group Companies, or any of its suppliers has been advised of the possibility of such damages.
    </Typography>
    <Typography paragraph>
      <strong>Versions and Modifications</strong>
      <br></br>
      FlyClaim reserves the right to amend sections of these Terms at any time without further notice. The latest version will always be available on the FlyClaim website.
    </Typography>
    <Typography paragraph>
      FlyClaim regularly updates the features of our Services and these Terms to improve how we deliver our Services to you. As a result, any new features of our Services will be subject to the latest version of the Terms displayed on the FlyClaim website.
    </Typography>
    <Typography paragraph>
      Nonetheless, your use of the Services will be governed by the version of the Terms in place on the date in which you accepted them. If you are unsure of which version of the Terms applies to you, please contact FlyClaim at admin@flyclaim.ca and we’ll provide them to you.
    </Typography>
    <Typography paragraph>
      <strong>Entire Agreement</strong>
      <br></br>
      These Terms are the complete and exclusive statement relating to its subject matter and supersede all prior communications (oral or in writing) between you and FlyClaim.
    </Typography>
    <Typography paragraph>
      You may not transfer any of your rights or obligations under these Terms without our prior written consent. FlyClaim may transfer any of its rights or obligations under these Terms to any FlyClaim Group Companies or any third party designated by FlyClaim.
    </Typography>
    <Typography paragraph>
      <strong>Governing Law and Jurisdiction</strong>
      <br></br>
      These Terms, Forms, and the agreement between FlyClaim and you, and any dispute or claim arising out of or in connection with them (including non-contractual disputes or claims), shall be governed by the laws of Canada, without regard to conflict of laws principle. This shall not affect mandatory provisions of the legal system that would apply without this choice of law clause, e.g. the mandatory provisions of the customer's country of residence.
    </Typography>
    <Typography paragraph>
      If any provision (or part thereof) of these Terms is held by any court, or other competent authority to be void or unenforceable in whole or part, such void provision shall be deemed to be replaced by an enforceable provision with the closest effect possible to the original provision and these Terms shall continue to be valid with respect to the other provisions and the remainder of the affected provisions.
    </Typography>
    <Typography paragraph>
      Any failure or delay by FlyClaim in enforcing (in whole or in part) any provision of these Terms will not be interpreted as a waiver of our rights or remedies.
    </Typography>
    <Typography paragraph>
      The English version of these Terms shall prevail in case of inconsistency to any other language version.
    </Typography>

  </FormContainerNotCentered>
);


function AppContent() {

  const pageTitle = "Claim Compensation for Flight Delays and Cancellations";
  const pageDescription = "Efficiently claim your rights for flight delays and cancellations.";
  const [alert, setAlert] = useState({ open: false, severity: 'success', message: '' });
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [formData, setFormData] = useState(null);
  const navigate = useNavigate();

  const handleLoginOpen = () => setShowLogin(true);
  const handleLoginClose = () => setShowLogin(false);

  const handleRegisterOpen = () => setShowRegister(true);
  const handleRegisterClose = () => setShowRegister(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsLoggedIn(true);
      setUserEmail(localStorage.getItem('email'));
    }
  }, []);

  const handleLoginSuccess = (email) => {
    setIsLoggedIn(true);
    setUserEmail(email);
    localStorage.setItem('email', email);
    setShowLogin(false);
  };

  const handleRegisterSuccess = (email) => {
    setUserEmail(email);
    localStorage.setItem('email', email);
    setShowRegister(false);
    handleLoginSuccess(email);
  };

  const handleFormSubmit = async (data) => {
    setAlert({ open: true, severity: 'success', message: 'Processing Claim.' });
    setFormData(data);
  
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        // Check if the user exists
        const userResponse = await fetch('https://clipurr.ddnsfree.com:443/flyclaim/api/check-user', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email: data.email }),
        });
  
        const userData = await userResponse.json();
        if (userData.exists) {
          setAlert({ open: true, severity: 'success', message: 'Login in order to track claim status.' });
          setShowLogin(true);
          setUserEmail(data.email);
        } else {
          setAlert({ open: true, severity: 'success', message: 'Register in order to track claim status.' });
          setShowRegister(true);
          setUserEmail(data.email);
        }
        return;
      }
  
      const accountResponse = await fetch('https://clipurr.ddnsfree.com:443/flyclaim/api/check-account', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
        },
        body: JSON.stringify({ email: data.email }),
      });
  
      const accountData = await accountResponse.json();
      if (accountData.success && accountData.hasCard) {
        const claimResponse = await fetch('https://clipurr.ddnsfree.com:443/flyclaim/api/submit-claim', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-access-token': token,
          },
          body: JSON.stringify(data),
        });
  
        if (claimResponse.ok) {
          setAlert({ open: true, severity: 'success', message: 'Claim submitted successfully.' });
        } else {
          setAlert({ open: true, severity: 'error', message: 'Failed to submit the claim. Please try again.' });
        }
      } else {
        setTimeout(() => {
          setShowPaymentForm(true);
          setAlert({ open: true, severity: 'success', message: 'Claim submitted successfully. \nPlease enter your card details for receiving the compensation. \nWe ABSOLUTELY WILL NOT CHARGE before that.' });
        }, 2000);
      }
    } catch (error) {
      setAlert({ open: true, severity: 'error', message: 'An error occurred. Please try again.' });
    }
  };


  const handlePaymentSuccess = async (paymentMethodId) => {
    try {
      setAlert({ open: true, severity: 'success', message: 'Processing card details.' });
      const token = localStorage.getItem('token');
      const paymentResponse = await fetch('https://clipurr.ddnsfree.com:443/flyclaim/api/save-payment-info', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
        },
        body: JSON.stringify({ paymentMethodId, email: formData.email }),
      });

      const paymentData = await paymentResponse.json();
      if (paymentData.success) {
        const claimResponse = await fetch('https://clipurr.ddnsfree.com:443/flyclaim/api/submit-claim', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-access-token': token,
          },
          body: JSON.stringify(formData),
        });

        if (claimResponse.ok) {
          setAlert({ open: true, severity: 'success', message: 'Card details saved.' });
          setShowPaymentForm(false);
        } else {
          setAlert({ open: true, severity: 'error', message: 'Something went wrong. Please try again.' });
        }
      } else {
        setAlert({ open: true, severity: 'error', message: 'Failed to save card details. Please try again.' });
      }
    } catch (error) {
      setAlert({ open: true, severity: 'error', message: 'An error occurred. Please try again.' });
    }
  };

  const handleAlertClose = () => {
    setAlert({ ...alert, open: false });
  };

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <ResponsiveAppBar
        logo={logo}
        isLoggedIn={isLoggedIn}
        navigate={navigate}
        setIsLoggedIn={setIsLoggedIn}
        handleLoginOpen={handleLoginOpen}
        handleRegisterOpen={handleRegisterOpen}
      />
      <Container maxWidth="sm" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', minHeight: '100vh', justifyContent: 'center' }}>
        <Routes>
          <Route
            path="/"
            element={
              showPaymentForm ? (
                <Elements stripe={stripePromise}>
                  <PaymentForm email={userEmail} setAlert={setAlert} formData={formData} onPaymentSuccess={handlePaymentSuccess} />
                </Elements>
              ) : (
                <StyledContainer>
                  <FeesCounter />
                  <ClaimForm setAlert={setAlert} isLoggedIn={isLoggedIn} email={userEmail} setUserEmail={setUserEmail} onSubmit={handleFormSubmit} />
                </StyledContainer>
              )
            }
          />
          <Route path="/our-mission" element={<OurMission />} />
          <Route path="/terms" element={<TermsAndConditions />} />
          <Route path="/status" element={isLoggedIn ? <ClaimStatus /> : <Navigate to="/" />} />
          <Route path="/support" element={isLoggedIn ? <SupportForm email={userEmail} /> : <Navigate to="/" />} />
        </Routes>
      </Container>
      <Dialog open={showLogin} onClose={handleLoginClose}>
        <DialogContent>
          <LoginForm onSuccess={handleLoginSuccess} email={userEmail} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLoginClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={showRegister} onClose={handleRegisterClose}>
        <DialogContent>
          <RegisterForm onSuccess={handleRegisterSuccess} email={userEmail} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRegisterClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <CustomAlert
        open={alert.open}
        severity={alert.severity}
        message={alert.message}
        onClose={handleAlertClose}
      />
    </>
  );
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <AppContent />
      </Router>
    </ThemeProvider>
  );
}

export default App;